import React, { ReactElement } from "react";

export default function Peo(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="143"
            height="144"
            fill="none"
            viewBox="0 0 143 144"
        >
            <mask
                id="mask0_201_59"
                style={{ maskType: "alpha" }}
                width="143"
                height="144"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0.715H142.358V143.073H0z"></path>
            </mask>
            <g clipPath="url(#clip0_201_59)" mask="url(#mask0_201_59)">
                <path
                    fill="#000"
                    d="M12.815 130.561c-4.85 0-8.797-3.947-8.797-8.797V35.611c0-4.85 3.947-8.797 8.797-8.797h44.59V14.033a2.417 2.417 0 012.412-2.412h21.669a2.417 2.417 0 012.412 2.412v12.786H129.8c4.85 0 8.797 3.947 8.797 8.797v48.697h-4.818V35.611a3.983 3.983 0 00-3.979-3.979H83.998l-.239 1.647c-.94 6.46-6.57 11.33-13.105 11.33-6.534 0-12.165-4.87-13.105-11.33l-.239-1.647H12.815a3.983 3.983 0 00-3.979 3.979v86.158c0 1.063.415 2.061 1.169 2.81a3.96 3.96 0 002.81 1.164h67.82v4.818h-67.82zM62.23 31.366c0 4.649 3.777 8.425 8.425 8.425 4.648 0 8.425-3.776 8.425-8.425V16.44H62.235v14.927h-.006z"
                ></path>
                <path
                    fill="#000"
                    d="M110.841 132.68a2.396 2.396 0 01-1.456-.494l-18.858-14.412a2.41 2.41 0 112.933-3.83l17.062 13.047 23.868-27.135a2.407 2.407 0 011.811-.818c.59 0 1.153.213 1.594.6a2.418 2.418 0 01.218 3.406l-25.35 28.824a2.405 2.405 0 01-1.806.818h-.011l-.005-.006zm-87.146-24.818a2.413 2.413 0 01-2.412-2.412v-8.053c0-.924.536-1.774 1.37-2.173l18.736-8.972-5.535-7.782a2.39 2.39 0 01-.446-1.397V64.387c0-8.34 6.683-14.874 15.214-14.874s15.214 6.534 15.214 14.874v12.686c0 .488-.149.961-.425 1.365l-5.381 7.83 17.95 8.972a2.394 2.394 0 011.333 2.157v8.053a2.417 2.417 0 01-2.412 2.412H23.695zm26.927-53.526c-5.827 0-10.396 4.415-10.396 10.056v11.916l6.747 9.487c.414.58.547 1.312.371 2.003a2.388 2.388 0 01-1.29 1.567l-19.948 9.551v4.128h48.389V98.89l-19.097-9.541a2.402 2.402 0 01-1.254-1.551 2.423 2.423 0 01.345-1.971l6.529-9.498V64.392c0-5.636-4.563-10.056-10.396-10.056zM88.795 84.58a2.413 2.413 0 01-2.411-2.412 2.413 2.413 0 012.411-2.412h17.61a2.417 2.417 0 012.412 2.412 2.417 2.417 0 01-2.412 2.412h-17.61zm0-18.37a2.413 2.413 0 01-2.411-2.412 2.413 2.413 0 012.411-2.411h27.576a2.417 2.417 0 012.412 2.411 2.417 2.417 0 01-2.412 2.412H88.795z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_201_59">
                    <path
                        fill="#fff"
                        d="M0 0H134.584V121.059H0z"
                        transform="translate(4.018 11.621)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}
