import React from "react";

function DebtRelief() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="55"
            height="55"
            fill="none"
            viewBox="0 0 55 55"
        >
            <mask
                id="mask0_2_2280"
                style={{ maskType: "alpha" }}
                width="55"
                height="55"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H55V55H0z"></path>
            </mask>
            <g clipPath="url(#clip0_2_2280)" mask="url(#mask0_2_2280)">
                <path
                    fill="#EB7F58"
                    d="M41.735 45.236a.91.91 0 01-.91-.91v-1.678h-2.973a.91.91 0 01-.908-.91c0-.5.408-.908.908-.908h4.53a2.324 2.324 0 100-4.652h-1.356c-1.406 0-2.7-.706-3.466-1.88a4.103 4.103 0 01-.285-3.983 4.109 4.109 0 013.212-2.364l.335-.043V26.21c0-.5.408-.91.909-.91.5 0 .909.41.909.91v1.679h2.973c.5 0 .909.408.909.909a.91.91 0 01-.909.909h-4.53a2.325 2.325 0 100 4.652h1.356c1.406 0 2.7.705 3.467 1.88a4.106 4.106 0 01.285 3.978 4.1 4.1 0 01-3.212 2.365l-.335.042v1.699a.91.91 0 01-.91.909v.004zM13.261 45.236a.91.91 0 01-.909-.91v-1.678H9.38a.91.91 0 01-.909-.91c0-.5.409-.908.91-.908h4.528a2.324 2.324 0 100-4.652h-1.355a4.12 4.12 0 01-3.466-1.884 4.106 4.106 0 01-.285-3.979 4.109 4.109 0 013.216-2.364l.334-.043V26.21c0-.5.409-.91.91-.91.5 0 .908.41.908.91v1.679h2.974c.5 0 .909.408.909.909a.91.91 0 01-.91.909h-4.529a2.324 2.324 0 100 4.652h1.356c1.406 0 2.7.705 3.466 1.88a4.106 4.106 0 01.285 3.978 4.1 4.1 0 01-3.212 2.365l-.335.042v1.699a.91.91 0 01-.909.909l-.004.004zM27.5 45.236a.91.91 0 01-.909-.91v-1.678h-2.973a.91.91 0 01-.91-.91c0-.5.41-.908.91-.908h4.529c.828 0 1.602-.447 2.014-1.163a2.343 2.343 0 000-2.327 2.337 2.337 0 00-2.014-1.163h-1.352c-1.405 0-2.7-.705-3.466-1.88a4.103 4.103 0 01-.285-3.982 4.109 4.109 0 013.216-2.364l.335-.043V26.21c0-.5.408-.91.909-.91.5 0 .909.41.909.91v1.679h2.973c.5 0 .909.408.909.909a.91.91 0 01-.909.909h-4.53a2.324 2.324 0 100 4.652h1.353c1.405 0 2.7.705 3.466 1.88a4.096 4.096 0 01.285 3.978 4.1 4.1 0 01-3.212 2.365l-.335.042v1.699a.91.91 0 01-.91.909l-.003.004z"
                ></path>
                <path
                    fill="#010101"
                    d="M6.791 53a4.763 4.763 0 01-3.39-1.402A4.757 4.757 0 012 48.208V10.674c0-1.278.497-2.484 1.402-3.389a4.757 4.757 0 013.39-1.402H8.47v-.385a3.46 3.46 0 011.025-2.473A3.489 3.489 0 0111.967 2h1.294a3.46 3.46 0 012.473 1.024 3.473 3.473 0 011.025 2.473v.385h21.479v-.385a3.46 3.46 0 011.024-2.473A3.489 3.489 0 0141.735 2h1.294c.932 0 1.814.362 2.472 1.024a3.482 3.482 0 011.025 2.473v.385h1.679c1.282 0 2.484.497 3.39 1.402a4.763 4.763 0 011.401 3.39v37.531a4.763 4.763 0 01-1.402 3.39 4.811 4.811 0 01-3.389 1.401H6.791V53zm-2.973-4.791c0 .793.308 1.54.87 2.103.563.562 1.31.87 2.103.87H48.21c.782 0 1.548-.316 2.103-.87.562-.563.87-1.306.87-2.103V19.347H3.818V48.209zM6.79 7.7c-.793 0-1.54.308-2.103.87a2.954 2.954 0 00-.87 2.103v6.856h47.364v-6.856c0-.793-.308-1.54-.87-2.103a2.954 2.954 0 00-2.103-.87h-1.68v2.973c0 .932-.361 1.814-1.024 2.473a3.473 3.473 0 01-2.472 1.024h-1.294a3.46 3.46 0 01-2.473-1.024 3.466 3.466 0 01-1.024-2.473V7.7h-21.48v2.973a3.46 3.46 0 01-1.024 2.473 3.466 3.466 0 01-2.473 1.024h-1.294A3.46 3.46 0 019.5 13.146a3.466 3.466 0 01-1.025-2.473V7.7H6.791zm34.944-3.882c-.925 0-1.68.755-1.68 1.679v5.176c0 .925.755 1.68 1.68 1.68h1.294a1.68 1.68 0 001.68-1.68V5.497a1.69 1.69 0 00-.494-1.186 1.696 1.696 0 00-1.186-.493h-1.294zm-29.768 0c-.924 0-1.679.755-1.679 1.679v5.176c0 .925.755 1.68 1.68 1.68h1.293c.447 0 .87-.174 1.187-.493.316-.316.493-.74.493-1.187V5.497a1.68 1.68 0 00-1.68-1.68h-1.294z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_2_2280">
                    <path
                        fill="#fff"
                        d="M0 0H51V51H0z"
                        transform="translate(2 2)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default DebtRelief;
