import React from "react";

function TaxDebtRelief() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="55"
            height="55"
            fill="none"
            viewBox="0 0 55 55"
        >
            <mask
                style={{ maskType: "alpha" }}
                width="55"
                height="55"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H55V55H0z"></path>
            </mask>
            <g clipPath="url(#clip0_2_2317)">
                <path
                    fill="#010101"
                    d="M7.642 34.843l-5.45-15.468c-.613-1.75.284-3.695 2-4.336L36.309 3.215a3.3 3.3 0 012.556.092 3.348 3.348 0 011.738 1.892l4.59 12.695-1.354.494-4.587-12.692a1.9 1.9 0 00-.985-1.074 1.878 1.878 0 00-1.458-.049L4.69 16.398c-.982.365-1.495 1.484-1.14 2.496L9 34.36l-1.358.485v-.003z"
                ></path>
                <path
                    fill="#010101"
                    d="M13.239 52c-4.679 0-8.485-3.84-8.485-8.556 0-4.256 3.007-7.813 7.155-8.46l.348-.055V23.03c0-1.767 1.497-3.205 3.34-3.205H49.76c1.784 0 3.239 1.438 3.239 3.205v21.202c0 1.824-1.452 3.305-3.239 3.305H20.687l-.12.2C18.973 50.366 16.165 52 13.24 52zm0-15.903c-4.014 0-7.28 3.297-7.28 7.344 0 4.048 3.266 7.345 7.28 7.345 4.013 0 7.28-3.297 7.28-7.345 0-4.047-3.267-7.344-7.28-7.344zm.222-1.214l.382.027c4.419.32 7.88 4.067 7.88 8.531 0 .81-.112 1.601-.338 2.352l-.159.534h28.535c.54 0 1.052-.22 1.44-.622.384-.4.594-.92.594-1.472v-13.77H13.461v4.42zm2.135-13.846c-1.177 0-2.135.896-2.135 1.994v2.91h38.334v-2.91c0-1.12-.893-1.994-2.034-1.994H15.596z"
                ></path>
                <path
                    fill="#EB7F58"
                    d="M11.665 47.109a.513.513 0 01-.4-.166l-2.933-2.95a.6.6 0 010-.867.604.604 0 01.43-.184c.158 0 .314.064.43.184l2.47 2.483 5.615-5.67a.604.604 0 01.43-.184c.159 0 .314.065.43.184a.6.6 0 010 .868l-6.057 6.09c-.134.132-.287.212-.418.212h.003z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_2_2317">
                    <path
                        fill="#fff"
                        d="M0 0H51V49H0z"
                        transform="translate(2 3)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default TaxDebtRelief;
