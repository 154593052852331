import { ReactElement } from "react";
import CreditRepair from "./icons/creditRepair";
import DebtRelief from "./icons/debtRelief";
import Hro from "./icons/hro";
import Payroll from "./icons/payroll";
import Peo from "./icons/peo";
import TaxDebtRelief from "./icons/taxDebtRelief";

interface Map {
    [key: string]: {
        label?: string;
        icon?: ReactElement;
        description?: string;
    };
}
const map: Map = {
    "tax-debt-relief": {
        label: "Resolving Tax Debt",
        icon: <TaxDebtRelief />,
    },
    "debt-relief": { label: "Managing My Debt", icon: <DebtRelief /> },
    "credit-repair": {
        label: "Repairing My Credit Score",
        icon: <CreditRepair />,
    },
    peo: {
        icon: <Peo />,
        description:
            "This solution is for SMBs looking to collaborate with a co-employment provider to share liabilities, and take a more active role in managing HR responsibilities.",
    },
    "payroll-services": {
        icon: <Payroll />,
        description:
            "This solution is ideal for businesses seeking straightforward payroll software that automatically updates tax regulations, provides task reminders for employers, and demands minimal user input.",
    },
    hro: {
        icon: <Hro />,
        description:
            "This solution is ideal for large companies with HR staff who need à la carte offerings to manage things like policy-making, payroll, and employee benefits.",
    },
};
map["peo-employee-leasing"] = map["peo"];
map["employee-leasing"] = map["peo"];
map["payroll"] = map["payroll-services"];

export default map;
