import React from "react";

function CreditRepair() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="55"
            height="55"
            fill="none"
            viewBox="0 0 55 55"
        >
            <mask
                style={{ maskType: "alpha" }}
                width="55"
                height="55"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H55V55H0z"></path>
            </mask>
            <g clipPath="url(#clip0_2_2288)">
                <path
                    fill="#010101"
                    d="M27.506 23.59c-5.332 0-9.67 4.411-9.67 9.856s4.329 9.857 9.67 9.857c5.342 0 9.67-4.412 9.67-9.857 0-5.445-4.325-9.857-9.67-9.857zm6.097 16.062a8.508 8.508 0 01-12.185 0c-3.36-3.425-3.36-8.983 0-12.409a8.508 8.508 0 0112.185 0c3.36 3.426 3.36 8.984 0 12.41z"
                ></path>
                <path
                    fill="#010101"
                    d="M27.506 43.44c-5.406 0-9.804-4.483-9.804-9.994 0-5.51 4.398-9.991 9.804-9.991 5.406 0 9.804 4.483 9.804 9.991s-4.398 9.995-9.804 9.995zm0-19.716c-5.258 0-9.537 4.361-9.537 9.722 0 5.36 4.279 9.723 9.537 9.723 5.259 0 9.537-4.362 9.537-9.723 0-5.36-4.278-9.722-9.537-9.722zm0 18.627a8.631 8.631 0 01-6.183-2.605 8.941 8.941 0 01-2.558-6.3c0-2.38.909-4.618 2.558-6.3a8.649 8.649 0 0112.372 0 8.941 8.941 0 012.559 6.3c0 2.38-.91 4.619-2.559 6.3a8.663 8.663 0 01-6.189 2.605zm.01-17.538a8.4 8.4 0 00-6.002 2.524 8.669 8.669 0 00-2.482 6.11c0 2.307.881 4.477 2.482 6.108a8.39 8.39 0 0011.997 0 8.668 8.668 0 002.482-6.109 8.668 8.668 0 00-2.482-6.109 8.37 8.37 0 00-5.996-2.524z"
                ></path>
                <path
                    fill="#EB7F58"
                    d="M25.375 30.312c1.802-1.115 5.046-.389 5.046 1.027 0 .714 1.06.714 1.06 0 0-1.353-1.496-2.455-3.437-2.627l.03-.877a.535.535 0 00-.531-.551c-.338 0-.571.229-.571.542v.886c-.827.075-1.57.313-2.141.67-.774.485-1.263 1.168-1.263 1.957 0 2.076 1.942 2.367 3.883 2.649 1.483.216 2.97.441 2.97 1.578 0 1.384-3.16 2.107-4.973 1.07-.476-.268-.774-.616-.826-.982-.095-.702-1.133-.573-1.05.128.18 1.49 1.928 2.27 3.394 2.411v.886c0 .714 1.06.714 1.06 0l.03-.886c1.929-.185 3.415-1.287 3.415-2.627 0-2.076-1.94-2.367-3.882-2.649-1.483-.216-2.97-.442-2.97-1.578 0-.379.286-.745.752-1.027h.004z"
                ></path>
                <path
                    fill="#EB7F58"
                    d="M27.497 39.75c-.329 0-.663-.23-.663-.67v-.765c-1.58-.185-3.213-1.02-3.395-2.514a.646.646 0 01.566-.742.648.648 0 01.746.576c.046.32.316.632.762.883 1.238.71 3.127.557 4.11-.032.303-.181.666-.488.666-.92 0-1.027-1.453-1.24-2.857-1.444-1.873-.272-3.996-.579-3.996-2.78 0-.798.47-1.534 1.324-2.073.571-.357 1.29-.588 2.08-.68v-.763c0-.392.298-.677.706-.677a.65.65 0 01.48.207.68.68 0 01.183.485l-.027.749c1.996.219 3.43 1.362 3.43 2.749 0 .88-1.323.88-1.323 0 0-.451-.384-.767-.707-.952-1.02-.586-2.924-.708-4.14.04-.436.264-.688.596-.688.912 0 1.03 1.453 1.24 2.857 1.443 1.873.273 3.996.58 3.996 2.78 0 1.379-1.432 2.525-3.419 2.75l-.028.767c0 .435-.334.664-.663.664v.006zm-3.394-4.428c-.022 0-.04 0-.062.003-.19.025-.368.172-.337.441.165 1.375 1.815 2.151 3.274 2.292l.12.013v1.008c0 .551.795.551.795 0l.037-1.008.117-.01c1.877-.178 3.292-1.249 3.292-2.492 0-1.97-1.83-2.236-3.765-2.514-1.444-.21-3.084-.448-3.084-1.713 0-.42.289-.827.817-1.143 1.336-.823 3.326-.664 4.408-.044.543.31.841.733.841 1.19 0 .551.796.551.796 0 0-1.252-1.425-2.323-3.314-2.492l-.126-.013.037-1.005a.409.409 0 00-.111-.288.39.39 0 00-.289-.125c-.264 0-.44.162-.44.407v1.008l-.119.01c-.798.071-1.517.297-2.085.65-.774.486-1.198 1.14-1.198 1.842 0 1.97 1.83 2.236 3.765 2.514 1.447.21 3.084.448 3.084 1.713 0 .445-.276.842-.795 1.155-1.045.623-3.014.815-4.374.035-.516-.294-.836-.676-.894-1.08-.034-.248-.209-.351-.387-.351l-.003-.003z"
                ></path>
                <path
                    fill="#010101"
                    d="M27.506 7.138c-6.997 0-13.343 2.89-17.938 7.577C3.001 21.416.452 31.37 3.278 40.648A26.326 26.326 0 006.57 47.57c.182.291.513.373.79.21l7.48-4.522a.605.605 0 00.171-.851c-4.561-6.285-3.944-15.07 1.508-20.632a15.34 15.34 0 0121.99 0c4.115 4.183 5.636 10.433 3.784 16.167a16.175 16.175 0 01-2.252 4.44c-.227.303-.147.698.148.873l7.479 4.522c.252.15.583.081.755-.176 2.894-4.29 4.448-9.337 4.448-14.616C52.868 18.71 41.512 7.138 27.506 7.138zM13.727 42.574L7.243 46.5c-4.893-7.612-5.268-17.425-.946-25.41l6.472 3.918c-2.915 5.573-2.537 12.368.961 17.566h-.003zm13.208-26.59c-5.67.197-10.714 3.287-13.607 8.018l-6.472-3.917c4.22-7.004 11.687-11.573 20.079-11.77v7.668zm1.143 0V8.314c8.391.197 15.858 4.769 20.078 11.773l-6.472 3.918c-2.893-4.722-7.924-7.822-13.606-8.02v-.003zm19.7 30.516l-6.484-3.926c3.499-5.21 3.864-11.996.962-17.566l6.471-3.918c4.322 7.985 3.944 17.801-.949 25.41z"
                ></path>
                <path
                    fill="#010101"
                    d="M7.065 48a.713.713 0 01-.605-.357 26.44 26.44 0 01-3.308-6.958c-2.81-9.23-.387-19.219 6.32-26.07C14.29 9.705 20.694 7 27.507 7 41.565 7 53 18.66 53 32.989c0 5.276-1.545 10.355-4.472 14.695a.69.69 0 01-.931.213l-7.476-4.522a.694.694 0 01-.332-.47.753.753 0 01.148-.6 15.95 15.95 0 002.23-4.403c1.83-5.67.393-11.814-3.754-16.029a15.226 15.226 0 00-21.802 0c-5.357 5.461-5.998 14.253-1.496 20.456.117.166.157.376.117.577a.715.715 0 01-.325.47l-7.48 4.52a.71.71 0 01-.362.104zM27.506 7.27c-6.754.006-13.085 2.683-17.846 7.54C3.02 21.587.624 31.472 3.404 40.606a26.164 26.164 0 003.277 6.889c.144.232.396.297.611.169l7.476-4.521a.44.44 0 00.203-.295.475.475 0 00-.07-.363c-4.58-6.306-3.926-15.252 1.523-20.804a15.491 15.491 0 0122.176 0c4.218 4.287 5.68 10.534 3.818 16.304a16.165 16.165 0 01-2.273 4.478.487.487 0 00-.098.385.442.442 0 00.206.291l7.48 4.525c.193.119.448.06.577-.135 2.893-4.293 4.423-9.321 4.423-14.541 0-14.181-11.316-25.717-25.227-25.717V7.27zm20.315 39.412l-6.714-4.068.08-.119a17.075 17.075 0 00.952-17.428l-.059-.113 6.7-4.055.064.122a25.13 25.13 0 01-.955 25.551l-.07.107.002.003zm-6.336-4.152l6.253 3.788a24.847 24.847 0 00.94-25.04l-6.244 3.78a17.342 17.342 0 01-.95 17.472zM7.203 46.682l-.07-.107a25.136 25.136 0 01-.953-25.55l.065-.122 6.699 4.055-.059.112a17.029 17.029 0 00.953 17.425l.08.12-6.715 4.067zm-.857-25.407a24.867 24.867 0 00.94 25.044l6.254-3.79a17.314 17.314 0 01-.95-17.471l-6.244-3.78v-.003zm7.025 2.91l-6.7-4.056.071-.116C11.09 12.8 18.635 8.375 26.932 8.18h.135v7.935l-.13.006c-5.525.194-10.572 3.169-13.499 7.957l-.067.112v-.006zm-6.33-4.147l6.244 3.78c2.96-4.763 7.998-7.731 13.518-7.966v-7.4c-8.106.239-15.475 4.56-19.763 11.59v-.004zm34.6 4.146l-.067-.113c-2.934-4.79-7.98-7.762-13.5-7.956h-.129V8.177h.135c8.297.2 15.843 4.625 20.19 11.84l.07.115-6.699 4.055v-.003zm-13.428-8.332c5.513.235 10.55 3.203 13.517 7.966l6.245-3.78C43.687 13.012 36.318 8.691 28.212 8.453v7.402-.003z"
                ></path>
                <path
                    fill="#010101"
                    d="M38.917 12.85l-2.276 2.307-.743-.758c-.538-.535-1.34.291-.811.827l1.155 1.177c.23.222.583.222.81 0l2.676-2.727c.525-.548-.286-1.366-.811-.827z"
                ></path>
                <path
                    fill="#010101"
                    d="M36.647 16.704a.721.721 0 01-.497-.204l-1.158-1.18c-.32-.326-.24-.752.006-1.005.245-.254.666-.342.992-.016l.65.664 2.182-2.214c.32-.326.737-.244.986.006.248.25.334.68.015 1.011l-2.675 2.728a.714.714 0 01-.5.203v.007zm-1.14-2.346c-.12 0-.236.06-.319.147-.154.157-.215.414-.006.624l1.155 1.177a.45.45 0 00.626 0l2.673-2.724c.209-.216.144-.473-.013-.63-.156-.156-.405-.219-.61-.006l-2.369 2.405-.835-.852a.424.424 0 00-.298-.134l-.004-.007zM18.362 15.38l-.642-.655.642-.655c.525-.548-.285-1.365-.81-.826l-.643.654-.642-.654c-.537-.536-1.339.278-.81.826l.641.655-.641.654c-.526.548.273 1.365.81.827l.642-.655.642.655c.538.535 1.34-.291.811-.827z"
                ></path>
                <path
                    fill="#010101"
                    d="M15.877 16.516a.724.724 0 01-.507-.223c-.245-.25-.328-.68-.009-1.011l.547-.557-.547-.558c-.32-.335-.24-.76.006-1.011.246-.25.667-.332.992-.01l.547.558.547-.558c.32-.325.737-.244.986.007.249.25.335.68.012 1.011l-.547.558.547.557c.32.325.24.751-.006 1.005-.246.254-.666.341-.992.016l-.547-.558-.546.558a.68.68 0 01-.486.213l.003.003zm0-3.31a.441.441 0 00-.316.144c-.154.157-.215.413-.006.63l.734.748-.734.748c-.21.216-.148.473.006.63.153.156.405.219.617.006l.734-.749.735.749c.211.21.463.147.617-.013.153-.156.215-.413.006-.623l-.734-.748.734-.749c.209-.216.144-.472-.012-.629-.154-.156-.406-.22-.615-.006l-.734.748-.734-.748a.413.413 0 00-.3-.135l.002-.003zM49.323 30.8l-2.276 2.32-.743-.757c-.538-.536-1.34.278-.81.826l1.154 1.165a.56.56 0 00.81 0l2.676-2.727c.526-.548-.273-1.365-.81-.827z"
                ></path>
                <path
                    fill="#010101"
                    d="M47.053 34.664a.71.71 0 01-.5-.213l-1.155-1.165c-.32-.335-.24-.76.006-1.01.246-.251.667-.333.992-.01l.651.663 2.184-2.226c.326-.325.747-.24.992.01.246.25.329.68.01 1.011l-2.676 2.727a.693.693 0 01-.5.213h-.004zm-1.142-2.342a.441.441 0 00-.317.144c-.153.157-.215.413-.006.63l1.155 1.164a.425.425 0 00.624 0l2.675-2.727c.209-.216.148-.473-.006-.63-.154-.156-.406-.219-.617-.006l-2.369 2.415-.838-.852a.413.413 0 00-.301-.135v-.003zM5.84 32.222l.641.654-.642.655c-.525.548.286 1.365.811.826l.642-.654.642.654c.538.536 1.34-.29.811-.826l-.642-.655.642-.654c.525-.548-.273-1.365-.81-.827l-.643.655-.642-.655c-.537-.535-1.339.291-.81.827z"
                ></path>
                <path
                    fill="#010101"
                    d="M6.266 34.667a.726.726 0 01-.506-.219c-.25-.25-.335-.68-.013-1.011l.547-.558-.547-.557c-.32-.326-.24-.755.006-1.005.246-.254.667-.341.993-.013l.546.558.547-.558c.329-.325.746-.244.992.007.246.25.329.68.01 1.011l-.547.557.546.558c.32.325.24.754-.006 1.005-.246.254-.666.341-.992.016l-.547-.558-.546.558a.664.664 0 01-.483.212v-.003zm-.003-3.31c-.12 0-.236.06-.32.148-.153.156-.214.413-.005.623l.734.748-.734.749c-.21.216-.145.472.012.629.157.157.405.22.611.006l.734-.748.734.748c.212.213.464.148.618-.012.153-.157.215-.414.006-.623l-.734-.749.734-.748c.209-.216.147-.473-.006-.63-.154-.156-.406-.219-.618-.006l-.734.749-.734-.749a.424.424 0 00-.298-.134zM33.757 26.773l1.926-5.435c.264-.642-.55-1.118-.962-.608l-4.143 4.13"
                ></path>
                <path
                    fill="#010101"
                    d="M33.88 26.817l-.25-.09 1.927-5.436c.113-.279-.022-.482-.172-.573a.416.416 0 00-.562.1l-4.153 4.14-.185-.195 4.144-4.13a.679.679 0 01.894-.15c.258.16.445.501.28.905l-1.923 5.43z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_2_2288">
                    <path
                        fill="#fff"
                        d="M0 0H51V41H0z"
                        transform="translate(2 7)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default CreditRepair;
